import React, { useState } from 'react'
import PropTypes from 'prop-types';
import {graphql} from 'gatsby'
import {Link} from 'gatsby'
import Img from "gatsby-image"
import {format, distanceInWords, differenceInDays} from 'date-fns'
import {
  mapEdgesToNodes,
  filterOutDocsWithoutSlugs,
  filterOutDocsPublishedInTheFuture
} from '../../lib/helpers'
import Container from '../../components/container'
import GraphQLErrorList from '../../components/graphql-error-list'
import SEO from '../../components/seo'
import Layout from '../../containers/layout'
import styled from 'styled-components'
import { ContainerFullWidth, ContainerMain, Backdrop, RelatedPagesPanel, RelatedPagesGroup, PanelLabel } from '../../containers'
import Hero from '../../components/hero'
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import Switch from '@material-ui/core/Switch';
import NumberFormat from 'react-number-format';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Legend
} from 'recharts';
import { MdHelpOutline, MdHelp, MdChevronRight } from "react-icons/md";
import ReactTooltip from 'react-tooltip';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { GraphDesktop, GraphMobile, AxisLabel } from '../../components/GraphComponents'
import { ThemedAccordion, FinancesTableWrapper, FinancesTableGrid, FinancesTableRow, FinancesTableRowREI } from '../../components/CustomAccordion'
import Sidebar from '../../components/Sidebar'
import { IRR } from '@formulajs/formulajs'
import { GumroadAd } from '../../components/GumroadAd'
import { InputGroup, InputSectionTitle, SectionLabel, HorizontalLine, FlexInput, InputPanel, InputLabel, InputItem } from '../../components/inputs'

const H2 = styled.h2`
  font-weight: 300;
`

const CalculatorPanel = styled.div`
  margin: 0 auto;
  /* padding: 24px; */
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px;
  max-width: 1200px;

  @media (max-width: 500px) {
    border: none;
    border-radius: 0px;
  }
`

const CalculatorGrid = styled.div`
  padding: 24px;
  /* margin: 0 auto;
  box-sizing: border-box;
  border: 1px solid ${ props => props.theme.theme.border.secondary};
  background-color: ${props => props.theme.theme.bg.primary};
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.05);
  border-radius: 8px; */
  display: grid;
  /* grid-template-columns: minmax(300px, 1fr) 3fr; */
  grid-template-columns: 1fr;
  grid-gap: 48px;
  align-items: start;
  margin-bottom: 0px;
  /* max-width: 1200px; */

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    padding: 24px 24px 0 24px;
    border: none;
    border-radius: 0px;
  }

  p, ul {
    font-size: 0.9rem;
    font-family: 'Inter', sans-serif;
  }
`

const ThemedTextarea = styled(TextField)`
  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.primary};
  }

  &.MuiFormControl-root {
    margin: 0 0 0px 0;
    background-color: ${props => props.theme.theme.bg.primary};
  }

  .MuiFormLabel-root.Mui-focused {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.formBorder.primary};
  }

  .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline   {
    border-color: ${props => props.theme.theme.colors.green};
  }

  .MuiInputBase-input {
    color: ${props => props.theme.theme.text.primary};
  }
`

const ThemedTextareaPrimary = styled(ThemedTextarea)`
  &.MuiFormControl-root {
    margin: 24px 0;
  }

  &:hover{
    .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
      border-color: ${props => props.theme.theme.colors.green};
    }
  }

  .MuiFormLabel-root {
    color: ${props => props.theme.theme.text.secondary};
    font-weight: 500;
  }

  .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: ${props => props.theme.theme.colors.green};
  }
`

const ThemedSlider = styled(Slider)`
  &.MuiSlider-root {
    color: ${props => props.theme.theme.colors.green};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`

const Label = styled.h3`
  margin: auto 0px auto 0;
  font-size: 0.9rem;
  font-weight: 400;
  display: flex;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

const InlineLabel = styled.span`
  font-size: 0.9rem;
  text-transform: uppercase;
  margin: 0 0 8px 0;
  font-weight: 500;

  svg {
    margin: 0 0 0 8px;
    height: 1.4rem;
  }
`

// const SectionLabel = styled.h3`
//   font-size: 1rem;
//   text-transform: uppercase;
//   margin: 0 12px 0px 0;
//   font-weight: 500;
//   display: flex;
//   flex-wrap: nowrap;

//   svg {
//     margin: 0 0 0 8px;
//     height: 1.4rem;
//   }
// `


const CardText300 = styled.h3`
  margin: 0;
  font-size: 0.9rem;
`

const CardText400 = styled.h4`
  margin: 0;
  font-size: 0.9rem;
  font-weight: 400;
`

const TooltipPanel = styled.div`
  background-color: ${props => props.theme.theme.bg.secondary};
  box-shadow: 0 1px 2px 2px rgba(0,0,0,0.1);
  padding: 10px 10px;
  border-radius: 8px;
  display: grid;
  grid-template-columns: 1fr;
`

const SliderGrid = styled.div`
  box-sizing: border-box;
  border: 0px solid ${ props => props.theme.theme.border.secondary};
  border-radius: 4px;
  display: grid;
  grid-template-columns: 200px 1fr;
  grid-gap: 16px;
  align-items: start;
`

// const FlexInput = styled.div`
//   margin: 0px 12px 12px 0;
//   width: 100%;
// `;

// const InputItem = styled.div`
//   display: grid;
//   grid-template-columns: auto 1fr;
//   grid-gap: 4px;
//   align-items: center;
//   margin: 0 0 0 0;

//   @media (max-width: 500px) {
//     margin: 0 0 8px 0;
//   }
// `;

const AllocationGroup = styled.div`
  box-sizing: border-box;
  border-radius: 8px;
  padding: 8px;
  margin: 0 0px 0px 0;
  width: 100%;

  h3 {
    text-transform: uppercase;
    font-weight: 500;
  }
`;

const AllocationGroupGray = styled(AllocationGroup)`
  border: 1px solid ${(props) => props.theme.theme.border.tertiary};
  color: ${(props) => props.theme.theme.text.primary};

  .MuiFormLabel-root.Mui-focused {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiFilledInput-underline:after {
    border-color: ${(props) => props.theme.theme.border.tertiary};
  }

  &.no-border {
    border: none;
  }

  &.shaded-bg-tertiary {
    background-color: ${(props) => props.theme.theme.bg.tertiary};
  }

  &.shaded-bg-inset {
    background-color: ${(props) => props.theme.theme.bg.inset};
  }
`;

// const InputPanel = styled(AllocationGroup)`
//   border: 1px solid ${(props) => props.theme.theme.border.tertiary};
//   color: ${(props) => props.theme.theme.text.primary};

//   display: grid;
//   grid-template-columns: auto auto auto auto;
//   grid-gap: 4px;
//   align-content: center;

//   background-color: ${(props) => props.theme.theme.bg.tertiary};

//   .suffix {
//     display: flex;
//     align-self: center;
//   }

//   @media (max-width: 500px) {
//     grid-template-columns: auto;
//   }
// `

const AllocationGroupRowTwoCol = styled.div`
  display: grid;
  grid-template-columns: minmax(150px, 1fr) 60px;
  grid-gap: 18px;
`;

const SliderBlack = styled(Slider)`
  &.MuiSlider-root {
    color: ${(props) => props.theme.theme.text.primary};
  }

  .MuiSlider-markLabel, .MuiSlider-markLabelActive {
    color: ${props => props.theme.theme.text.primary};
  }
`;

// const InputGroup = styled.div`
//   /* display: grid; */
//   display: flex;
//   flex-wrap: wrap;
//   /* grid-template-columns: repeat(auto-fill, minmax(250px, 1fr) ) ; */
//   /* grid-gap: 24px; */

//   @media (max-width: 500px) {
//     padding: 24px 12px 0 12px;
//   }
// `;

const CustomReactTooltip = styled(ReactTooltip)`
  max-width: 50vw;
`;

const ResultsPanel = styled(AllocationGroup)`
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
  border: 1px solid ${(props) => props.theme.theme.colors.green};
  padding: 12px;
  display: inline-flex;
  flex-wrap: wrap;
  width: auto;
  justify-content: flex-start;

  .label {
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 8px 0 0;
  }

  .result-value {
    background-color: rgba(0, 0, 0, 0.09);
    padding: 4px 8px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.42);
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    margin: 0 12px 0 0;
  }

  h3 {
    color: ${(props) => props.theme.theme.colors.green};
  }

  @media (max-width: 500px) {
    margin: 0px;
  }
`;

const ResultsGroup = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 0;

  @media (max-width: 500px) {
  }
`;

const ResultsTable = styled.div`
display: grid;
grid-template-columns: auto auto;
align-items: center;
align-content: start;
border: 2px solid ${(props) => props.theme.theme.colors.transparentGreen};
border-radius: 4px;


div {
  padding: 4px;
  /* height: 100%; */
}

div:nth-child(4n), div:nth-child(4n-1){
  background-color: ${(props) => props.theme.theme.colors.transparentGreen};
}

@media (max-width: 500px) {
    font-size: 0.9rem;
  }

`

const ResultsColumn = styled.div`
  margin: 12px 24px 0 0;

  h3 {
    margin: 0 0 12px 0;
  }


`

const ColumnsWrapper = styled.div`
  display: grid;
  /* grid-template-columns: 1fr 1fr; */
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr) ) ;
  grid-gap: 24px;
`

const GroupLabel = styled.h3`
  display: block;
  width: 100%;
  color: ${(props) => props.theme.theme.text.primary};
  text-transform: uppercase;
  margin: 0 0 24px 0;
  font-weight: 500;
  font-size: 0.9rem;
`

// const HorizontalLine = styled.div`
//   border: 0.5px solid ${ props => props.theme.theme.border.primary};
//   margin: 24px 0 24px ;
//   width: 100%;
// `

const ContainerTwoCol = styled(ContainerMain)`
  display: grid;
  grid-template-columns: ${props => props.theme.theme.contentWidths.content} 1fr;
  grid-gap: 48px;
  padding-right: 0px;
  align-items: start;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
    padding-right: 24px;
  }
`

const ContainerContent = styled.div`
  max-width: ${props => props.theme.theme.contentWidths.content};
`

// const InputSectionTitle = styled.div`
//   width: 100%;
//   display: grid;
//   grid-template-columns: auto 1fr;
//   align-items: center;
//   margin: 0 0 12px 0;
// `

const VerticallyCenteredText = styled.div`
  display: flex;
  align-self: center;
`

const ScrollLabel = styled.div`
  margin: 12px;
  display: flex;
  align-items: center;
`

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function NumberFormatPercentage(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      isNumericString
      suffix="%"
    />
  );
}

NumberFormatPercentage.propTypes = {
  inputRef: PropTypes.func.isRequired,
  // name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

const CapRateCalc = () => {

  function NumberFormatYears(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
      />
    );
  }

  function NumberFormatPerYear(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        prefix="$"
        suffix=" / year"
      />
    );
  }

  function NumberFormatAnnualIncrease(props) {
    const { inputRef, onChange, ...other } = props;
  
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        isNumericString
        suffix="% annual increase"
      />
    );
  }

  const [ interestRate, setInterestRate ] = useState(6)
  const [ purchasePrice, setPurchasePrice ] = useState(300000)
  const [ downPayment, setDownPayment ] = useState(20)
  const [ loanTerm, setLoanTerm ] = useState(30)
  const [ closingCosts, setClosingCosts ] = useState(7000)
  const [ rehabCosts, setRehabCosts ]= useState(0)

  const [ monthlyRent, setMonthlyRent ] = useState(2500)
  const [ annualRentIncrease, setAnnualRentIncrease ] = useState(3)
  const [ propertyManagementFee, setPropertyManagementFee ] = useState(10)
  const [ vacancyRate, setVacancyRate ] = useState(8)

  const [ annualPropertyTaxes, setAnnualPropertyTaxes] = useState(2000)
  const [ annualPropertyTaxesIncrease, setAnnualPropertyTaxesIncrease] = useState(3)
  const [ annualInsurance, setAnnualInsurance] = useState(1300)
  const [ annualInsuranceIncrease, setAnnualInsuranceIncrease] = useState(3)
  const [ annualHOAFees, setAnnualHOAFees] = useState(0)
  const [ annualHOAFeesIncrease, setAnnualHOAFeesIncrease] = useState(3)
  const [ annualMaintenance, setAnnualMaintenance] = useState(3000)
  const [ annualMaintenanceIncrease, setAnnualMaintenanceIncrease] = useState(3)
  const [ annualOtherExpenses, setAnnualOtherExpenses] = useState(0)
  const [ annualOtherExpensesIncrease, setAnnualOtherExpensesIncrease] = useState(3)

  const [ salePrice, setSalePrice] = useState(600000)
  const [ holdingPeriod, setHoldingPeriod] = useState(20)
  const [ saleExpenses, setSaleExpenses] = useState(9)

  const downPaymentValue = purchasePrice * ( downPayment / 100)
  const principal = parseFloat(purchasePrice) - parseFloat(downPaymentValue)
  const initialInvestment = parseFloat(downPaymentValue) + parseFloat(closingCosts) + parseFloat(rehabCosts)

  const annualPaymentPeriods = 12
  const rate = (interestRate/100) / annualPaymentPeriods

  const nper = annualPaymentPeriods * loanTerm
  const payment = principal * ( ( rate * Math.pow( 1 + rate, nper) ) / ( Math.pow( 1 + rate, nper) - 1) )

  console.log(payment)

  const calcInvestmentData = () => {
    let investmentData = []
    for(let i = 0; i <= holdingPeriod; i++){

      if(!i){

        // T0 data 

        console.log(initialInvestment)

        investmentData.push({
          year: i,
          annualIncome: 0,
          annualMortgagePayments: 0,
          annualExpenses: 0,
          annualCashFlow: -(initialInvestment) ,
          annualCoCReturn: 0,
          interestPortion: 0,
          principalPortion: 0,
          loanBalance: parseFloat(principal),
          totalEquity: downPaymentValue,
          NOI: 0
        })

      } else {
        const annualIncome = 12 * monthlyRent* Math.pow(1 + (annualRentIncrease/100), i-1) * ( 1 - ( propertyManagementFee/100) - ( vacancyRate / 100 ))
        const annualMortgagePayments = 12 * payment
        const annualExpenses = annualPropertyTaxes * Math.pow(1 + (annualPropertyTaxesIncrease/100), i-1) +
          annualInsurance * Math.pow(1 + (annualInsuranceIncrease/100), i-1) +
          annualHOAFees * Math.pow(1 + (annualHOAFeesIncrease/100), i-1) +
          annualMaintenance * Math.pow(1 + (annualMaintenanceIncrease/100), i-1) +
          annualOtherExpenses * Math.pow(1 + (annualOtherExpensesIncrease/100), i-1)
        let annualCashFlow = 0
        const annualCashFlowAdjusted = annualIncome - annualMortgagePayments - annualExpenses
        const interestPaid = investmentData[i-1].loanBalance * (interestRate/100)
        const principalGained = annualMortgagePayments - interestPaid
        const loanBalance = investmentData[i-1].loanBalance - principalGained
        const totalEquity = purchasePrice - loanBalance
        const NOI = annualIncome - annualExpenses

        if(i == holdingPeriod){
          annualCashFlow = annualIncome - annualMortgagePayments - annualExpenses + ((salePrice * (1- (saleExpenses/100) ) ) -  loanBalance)
        } else {
          annualCashFlow = annualIncome - annualMortgagePayments - annualExpenses
        }

        investmentData.push({
          year: i,
          annualIncome: annualIncome,
          annualMortgagePayments: annualMortgagePayments,
          annualExpenses: annualExpenses,
          annualCashFlow:  annualCashFlow,
          annualCoCReturn: annualCashFlowAdjusted / initialInvestment,
          interestPortion: interestPaid,
          principalPortion: principalGained,
          loanBalance: loanBalance,
          totalEquity: totalEquity,
          NOI: NOI
        })

      }
    }
    return investmentData
  }

  const investmentData = calcInvestmentData()
  const sumAnnualIncome = investmentData.reduce( (prev, next) => ( prev + next.annualIncome ), 0)
  const sumAnnualMortgagePayments = investmentData.reduce( (prev, next) => ( prev + next.annualMortgagePayments ), 0)
  const sumAnnualExpenses = investmentData.reduce( (prev, next) => ( prev + next.annualExpenses ), 0)
  const sumAnnualCashFlow = investmentData.reduce( (prev, next) => ( prev + next.annualCashFlow ), 0)
  const sumNOI = investmentData.reduce( (prev, next) => ( prev + next.NOI ), 0)
  const totalProfit = sumAnnualCashFlow


  console.log(investmentData)

  const cashflowsArray = investmentData.map(year => year.annualCashFlow)

  console.log(cashflowsArray)

  const IRRvalue = IRR(cashflowsArray, 0.1)
  const IRRpercent = (IRRvalue * 100).toFixed(2)
  const initialCapRate = (100 * (investmentData[1].NOI / purchasePrice) ).toFixed(2)
  const initialCashFlow = investmentData[1].annualCashFlow
  const initialNOI = investmentData[1].NOI
  const initialCoC = investmentData[1].annualCoCReturn * 100
  const propertyValueCAGR = 100 * ( Math.pow( (salePrice / purchasePrice), (1/holdingPeriod) ) - 1 )
        
  console.log(initialCoC)

  return (
    <Layout>
      <SEO
        title={'Cap Rate Calculator for Real Estate Investing'}
        description={'Analyze the Cap rate for your next real estate deal'}
      />
      <CustomReactTooltip />

      <CalculatorPanel>
        <CalculatorGrid>
          <form>
            <InputGroup>
            <InputSectionTitle>
              <SectionLabel>Property</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                  Property Market Value
                  <MdHelp
                    size="1.2rem"
                    data-tip={`This is the market value of the property.`}
                  />
                </InputLabel>
                <InputItem style={{ width: "150px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={purchasePrice}
                  onChange={event => setPurchasePrice(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>

            </InputGroup>

            <InputSectionTitle>
              <SectionLabel>Income</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <InputGroup>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <InputLabel>
                    Monthly Rent
                    <MdHelp
                      size="1.2rem"
                      data-tip={`This the total value of rent that is collected from your tenants each month. 
                      This does not include property management fees.`}
                    />
                  </InputLabel>
                  <InputItem style={{ width: "100px" }}>
                    <ThemedTextarea
                    required
                    id="outlined"
                    value={monthlyRent}
                    onChange={event => setMonthlyRent(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                  </InputItem>

                </InputPanel>
              </FlexInput>

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                  Property management fee
                  <MdHelp
                    size="1.2rem"
                    data-tip={`Property managers typically charge 10% of the monthly rent as a fee if you choose to use property management.`}
                  />
                </InputLabel>
                <InputItem style={{ width: "75px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={propertyManagementFee}
                  onChange={event => setPropertyManagementFee(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>
              

            <FlexInput style={{ width: "auto" }}>
              <InputPanel>
                <InputLabel>
                  Vacancy rate
                  <MdHelp
                    size="1.2rem"
                    data-tip={`You should budget for some amount of vacancy due to tenant turnover. One month of vacancy per year implies an 8% vacancy rate.`}
                  />
                </InputLabel>
                <InputItem style={{ width: "75px" }}>
                  <ThemedTextarea
                  required
                  id="outlined"
                  value={vacancyRate}
                  onChange={event => setVacancyRate(event.target.value)}
                  variant="outlined"
                  size="small"
                  fullWidth
                  InputProps={{
                    inputComponent: NumberFormatPercentage,
                  }}
                  />

                </InputItem>
              </InputPanel>
            </FlexInput>

            </InputGroup>

            <InputSectionTitle>
              <SectionLabel>Yearly Expenses</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <InputGroup>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <InputLabel>
                    Property Taxes
                    <MdHelp
                      size="1.2rem"
                      data-tip={`State property tax rates as a percentage of property value range from 0.28% in Hawaii to 2.49% in New Jersey.`}
                    />
                  </InputLabel>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualPropertyTaxes}
                    onChange={event => setAnnualPropertyTaxes(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                  
                    <span className='suffix'>per year</span>
                  
                  </InputItem>
                </InputPanel>
              </FlexInput>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <InputLabel>
                    Insurance
                    <MdHelp
                      size="1.2rem"
                      data-tip={`The national average for landlord insurance is $1,288 per year.`}
                    />
                  </InputLabel>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualInsurance}
                    onChange={event => setAnnualInsurance(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                    <span className='suffix'>per year</span>
                  </InputItem>
                </InputPanel>
              </FlexInput>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <InputLabel>
                    HOA Fees
                    <MdHelp
                      size="1.2rem"
                      data-tip={`Apartments, condos, and some single family homes have HOA fees. These fees will vary by 
                      property and locations but typically range between $200 and $300 per month.`}
                    />
                  </InputLabel>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualHOAFees}
                    onChange={event => setAnnualHOAFees(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                    <span className='suffix'>per year</span>
                  </InputItem>
                </InputPanel>
              </FlexInput>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <InputLabel>
                    Maintenance
                    <MdHelp
                      size="1.2rem"
                      data-tip={`A common rule of thumb is to budget for 1% of your 
                      property’s value to be spent each year on maintenance.`}
                    />
                  </InputLabel>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualMaintenance}
                    onChange={event => setAnnualMaintenance(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                    <span className='suffix'>per year</span>
                  </InputItem>
                </InputPanel>
              </FlexInput>

              <FlexInput style={{ width: "auto" }}>
                <InputPanel>
                  <InputLabel>
                    Other expenses
                    <MdHelp
                      size="1.2rem"
                      data-tip={`Other expenses could include utilities like water, sewer, gas, or 
                      trash collection or things like landscaping or snow removal.`}
                    />
                  </InputLabel>
                  <InputItem >
                    <ThemedTextarea style={{ width: "100px" }}
                    required
                    id="outlined"
                    value={annualOtherExpenses}
                    onChange={event => setAnnualOtherExpenses(event.target.value)}
                    variant="outlined"
                    size="small"
                    fullWidth
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                    />
                    <span className='suffix'>per year</span>
                  </InputItem>
                </InputPanel>
              </FlexInput>
            
            </InputGroup>

            <InputSectionTitle>
              <SectionLabel>Deal Analysis</SectionLabel>
              <HorizontalLine />
            </InputSectionTitle>

            <ResultsPanel>
              <ResultsColumn>
                

                <ResultsTable style={{marginBottom: "12px"}}>
                    <div> 
                        <span>
                        <strong>
                            Cap rate:
                        </strong>
                        </span>
                    </div>

                    <div>
                        <span>
                        <strong>
                            {initialCapRate}%
                        </strong>
                        </span>
                    </div>
                </ResultsTable>

                <SectionLabel>Itemized Breakdown</SectionLabel>

                <ResultsTable>

                  <div>Item</div>
                  <div>Annual</div>
                  <div>Income</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={(12*monthlyRent)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Vacancy ({vacancyRate}%)</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={-(12*monthlyRent*(vacancyRate/100))}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Management Fee ({propertyManagementFee}%)</div>
                  <div>
                  <span>
                      <NumberFormat
                        displayType={"text"}
                        value={-(12*monthlyRent*(propertyManagementFee/100))}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Property tax</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={-(annualPropertyTaxes)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Insurance</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={-(annualInsurance)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>HOA Fees</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={-(annualHOAFees)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Maintenance</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={-(annualMaintenance)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div>Other costs</div>
                  <div>
                    <span>
                      <NumberFormat
                        displayType={"text"}
                        value={-(annualOtherExpenses)}
                        thousandSeparator
                        prefix="$"
                      />
                    </span>
                  </div>
                  <div><strong>Net Operating Income (NOI)</strong></div>
                  <div>
                    <strong>
                      <NumberFormat
                        displayType={"text"}
                        value={initialNOI.toFixed(0)}
                        thousandSeparator
                        prefix="$"
                      />
                    </strong>
                  </div>

                </ResultsTable>

              </ResultsColumn>

            </ResultsPanel>

          </form>

        </CalculatorGrid>

      </CalculatorPanel>
    </Layout>
  )
}

export default CapRateCalc
